
import React from 'react';

const GoldStarStoriesPage = () => {

    return (

        <div>
        <br/><br/><br/><br/><br/>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gold Star Stories Page</div>
        </div>
    );

}

export default GoldStarStoriesPage;
