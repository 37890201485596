
import React from 'react';

const AboutUsPage = () => {

    return (

        <div>
        <br/><br/><br/><br/><br/>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;About Us Page</div>
        </div>
    );

}

export default AboutUsPage;
