const lngOptions = [
    { value: "en", name: "English" },
    { value: "es", name: "Español" },
    { value: "sw", name: "Swahili" },
    { value: "ru", name: "Russian" },
    { value: "uk", name: "Ukrainian" },
    { value: "ar", name: "Arabic" },
];

export default lngOptions;
